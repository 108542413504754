@import '~ht-styleguide/build/spacing';

.marginAuto {
  @include spacing(margin, 0 auto);
}

.flex {
  display: flex;
  &.flexDirectionColumn {
    flex-direction: column;
  }
  &.justifyContentCenter {
    justify-content: center;
  }
  &.justifyContentRight {
    justify-content: right;
  }
  &.alignItemsCenter {
    align-items: center;
  }
  &.alignItemsEnd {
    align-items: end;
  }
  &.alignItemsBaseline {
    align-items: baseline;
  }
  &.justifyContentSpaceBetween {
    justify-content: space-between;
  }
  &.justifyContentSpaceAround {
    justify-content: space-around;
  }
  &.alignItemsFlexStart {
    align-items: flex-start;
  }
  &.alignSelfFlexStart {
    align-self: flex-start;
  }
  &.justifyContentFlexEnd {
    justify-content: flex-end;
  }
  &.flexWrap {
    flex-wrap: wrap;
  }
  &.flexWrapInherit {
    flex-wrap: inherit;
  }
  &.flex1 {
    flex: 1;
  }
}

.positionAbsolute {
  position: absolute;
  &.top50 {
    top: 50%;
  }
  &.right50 {
    right: 50%;
  }
}

.positionRelative {
  position: relative;
}

.positionFixed {
  position: fixed !important;
}

.textAlignCenter {
  text-align: center;
}

.showDesktop {
  display: none;
  @include with-lg-grid {
    display: inherit;
    &.initial {
      display: initial;
    }
  }
}

.showMobileTablet {
  display: flex;
  justify-content: center;
  @include with-lg-grid {
    display: none;
  }
}

.showSmMdGrid {
  @include with-lg-grid {
    display: none;
  }
}

.showLgGrid {
  display: none;
  @include with-lg-grid {
    display: block;
  }
}

.width100 {
  width: 100%;
}

.width75 {
  width: 75%;
}

.width50 {
  width: 50%;
}

.width25 {
  width: 25%;
}

.width20 {
  width: 20%;
}

.height100 {
  height: 100%;
}
.minHeight100 {
  min-height: 100%;
}
