/* Make clicks pass-through */
#nprogress {
    pointer-events: none;
}

#nprogress .bar {
    background: #4eaeeb;

    position: fixed;
    z-index: 1031;
    top: 0;
    left: 0;

    width: 100%;
    height: 3px;
}

/* Fancy blur effect */
#nprogress .peg {
    display: block;
    position: absolute;
    right: 0px;
    width: 100px;
    height: 100%;
    box-shadow: 0px 2px 3px 1px rgba(91, 171, 243, 0.73);
    opacity: 1.0;

    -webkit-transform: rotate(3deg) translate(0px, -3px);
    -ms-transform: rotate(3deg) translate(0px, -3px);
    transform: rotate(3deg) translate(0px, -3px);
}

.nprogress-custom-parent {
    overflow: hidden;
    position: relative;
}

.nprogress-custom-parent #nprogress .bar {
    position: absolute;
}

