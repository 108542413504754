/* from ht-styleguide */

@import 'src/styles/vendor/gladly';
@import '~ht-styleguide/build/spacing_classes';
@import '~ht-styleguide/build/typography_classes';
@import '~ht-styleguide/build/variables';

// vendor
@import './vendor/reset.scss';
@import './vendor/slick/slick.scss';
@import './vendor/slick/slick-theme.scss';
// globals & local includes
@import 'src/styles/_variables';
@import 'src/styles/_layout';
@import 'src/styles/globals';
@import 'src/styles/typography';


*,
::before,
::after {
  background-repeat: no-repeat;
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  @include spacing(margin, 0);
  @include spacing(padding, 0);

  height: 100%;
  border: 0;
  vertical-align: baseline;
}

body {
  font-family: $ht-font-family !important;
  font-weight: $ht-font-weight-regular !important;
  font-style: normal;

  input,
  button,
  textarea {
    font-family: $ht-font-family !important;
  }

  animation: fadeInAnimation ease 0s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  @keyframes fadeInAnimation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid $ht-navy-200;
}

#__next {
  min-height: 100%;
  height: 100%;
  background-color: $ht-white;
}

.plainButton {
  appearance: none;
  @include spacing(padding, 0);
  @include spacing(margin, 0);

  background-color: transparent;
  border: none;
  &:focus,
  &:active {
    outline: none;
  }
  &:hover {
    cursor: pointer;
  }
}

.appContainer {
  height: 100%;
  -webkit-overflow-scrolling: touch;
}

:root {
  --desktop-spacing-promotional-bar: $desktop-spacing-promotional-bar;
  --mobile-spacing-promotional-bar: $mobile-spacing-promotional-bar;
}

a,
.text-link {
  color: $ht-teal-500;
  cursor: pointer;
  text-decoration: none;
  outline: none;

  &.large-link {
    @extend %large-link;
  }

  &.medium-link {
    @extend %medium-link;
  }

  &.small-link {
    @extend %small-link;
  }

  &:visited,
  &:focus {
    color: $ht-teal-500;
  }

  &:hover {
    color: $ht-blue-500;
    text-decoration: underline;
  }

  &.-no-decoration {
    &,
    &:visited,
    &:focus,
    &:hover {
      text-decoration: none;
    }
  }
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
p,
.p0,
.p1,
.p2,
span,
.caption {
  &.n600 {
    color: $ht-navy-300;
  }
  &.n300 {
    color: $ht-navy-300;
  }
  &.n700 {
    color: $ht-navy-700;
  }

  &.n900 {
    color: $ht-navy-900;
  }

  &.n000 {
    color: $ht-navy-000;
  }

  &.lightBlue {
    color: $ht-blue-500;
  }

  &.teal {
    color: $ht-teal-500;
  }

  &.white {
    color: $ht-white;
  }

  &.lightBlue700 {
    color: $ht-blue-700;
  }

  &.lightBlue300 {
    color: $ht-blue-300;
  }
}

// Page Level
.blockUIContainer {
  z-index: $ht-z-index-block-ui;
  position: fixed !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
