@import '~ht-styleguide/build/variables';
@import '~react-toastify/dist/ReactToastify.min.css';

.sui-search-box__text-input {
  font-size: 16px;
  line-height: 1.5;

  &::placeholder {
    color: $ht-navy-300;
  }
}
