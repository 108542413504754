/* stylelint-disable no-descending-specificity */
@import 'src/styles/mixins';
@import '~ht-styleguide/build/variables';
@import '~ht-styleguide/build/typography_placeholders';

$bar-transition-time: 1s;

// for animating height
.barWrapper {
  max-height: 0;
  height: 100%;
  // padding-bottom: 8px; // can't use here or else the animation will jump
  transition: max-height $bar-transition-time;
}

.barPadding {
  // TODO - hack to get smooth animation. Will need to revisit.
  padding-bottom: 8px;
}

.bar {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  border-radius: 3px;
  border: 1px solid $ht-white;
  padding: 8px;
  transform: translateY(-25px);
  opacity: 0;
  transition: $bar-transition-time;

  @include with-desktop {
    padding: 12px 16px;
  }

  &.showBar {
    transform: translateY(0);
    opacity: 1;
  }

  .statusIcon {
    margin-right: 12px;
    @extend %p1;
  }

  .copy {
    flex: 1;
    font-weight: $ht-font-weight-medium !important;
  }

  .closeIcon {
    margin-left: 12px;
    @extend %p1;
  }

  &.success {
    background-color: #eefff9;
    border-color: $ht-success-green-500;
    .copy {
      color: $ht-success-green-500;
    }
    .closeIcon {
      color: $ht-success-green-500;
      &:hover {
        color: $ht-success-green-700;
      }
    }
  }

  &.error {
    background-color: #ffebea;
    border-color: $ht-alert-red-500;
    .copy {
      color: $ht-alert-red-500;
    }
    .closeIcon {
      color: $ht-alert-red-500;
      &:hover {
        color: $ht-alert-red-700;
      }
    }
  }

  &.warning {
    background-color: #fff6e5;
    border-color: $ht-deep-yellow-500;
    .copy {
      color: $ht-deep-yellow-500;
    }
    .closeIcon {
      color: $ht-deep-yellow-500;
      &:hover {
        color: $ht-deep-yellow-700;
      }
    }
  }

  &.info {
    background-color: #f2fafe;
    border-color: $ht-blue-700;
    .statusIcon,
    .copy {
      color: $ht-blue-700;
    }
    .closeIcon {
      color: $ht-blue-700;
      &:hover {
        color: $ht-blue-700;
      }
    }
  }
}
