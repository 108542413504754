@import '~ht-styleguide/build/variables';
@import '~ht-styleguide/build/mixins';
@import '~ht-styleguide/build/grid_classes';
@import 'mixins';

// Colors
.n300 {
  color: $ht-navy-300;
}
.n700 {
  color: $ht-navy-700;
}
.n800 {
  color: $ht-navy-800;
}
.n900 {
  color: $ht-navy-900;
}
.n000 {
  color: $ht-navy-000;
}
.teal {
  color: $ht-teal-500;
}
.white {
  color: $ht-white;
}
.blue700 {
  color: $ht-blue-700 !important;
}
.blue {
  color: $ht-blue-500;
}
.blue500 {
  color: $ht-blue-500;
}
.blue300 {
  color: $ht-blue-300;
}
.alertRed500 {
  color: $ht-alert-red-500;
}
.successGreen500 {
  color: $ht-success-green-500;
}
.darkBlue700 {
  color: $ht-dark-blue-700;
}
.teal700 {
  color: #107286;
}

// text font weights
.text-weight-book {
  font-weight: $ht-font-weight-regular;
}

.text-weight-medium {
  font-weight: $ht-font-weight-medium;
}

// alignment
.text-align-center {
  text-align: center !important;
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

// BaseLayoutContent Utility
.nowrap {
  white-space: nowrap;
}

.line-through {
  text-decoration: line-through;
}

.underline {
  text-decoration: underline;
}

.subdued-text {
  opacity: 0.7;
}

.cursorPointer {
  cursor: pointer;
}

.uppercase {
  text-transform: uppercase;
}

.inlineBlock {
  display: inline-block;
}

.block {
  display: block;
}

/* Invisible Button to Wrap Content */
.invisibleButton {
  background: transparent;
  box-shadow: 0 0 0 transparent;
  border: 0 solid transparent;
  text-shadow: 0 0 0 transparent;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;

  &:hover {
    background: transparent;
    box-shadow: 0 0 0 transparent;
    border: 0 solid transparent;
    text-shadow: 0 0 0 transparent;
  }

  &:active {
    outline: none;
    border: none;
  }

  &:focus {
    outline: 0;
  }
}

.border-radius {
  &.small {
    border-radius: 2px;
  }
  &.medium {
    border-radius: 4px;
  }
  &.large {
    border-radius: 8px;
  }
}

// animation class
.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: $ht-navy-300;
  }

  100% {
    background-color: $ht-navy-100;
  }
}

.marginAuto {
  margin: 0 auto !important;
}

.positionRelative {
  position: relative;
}

.overflowHidden {
  overflow: hidden;
}

.htBlue700 {
  color: $ht-dark-blue-700;
}

.htTealGreen {
  color: $ht-teal-green;
}

.opacity40 {
  opacity: 0.4;
}
