
/*
  Position buttom in the lower right corner
 */
#custom-gladly-chat-button {
    position: fixed;
    padding: 10px;
    right: 20px;
    bottom: 40px;
}

/*
  By default we hide the button
*/
#custom-gladly-chat-button {
    display: none;
}

#custom-gladly-chat-button #with-active-chat {
    display: none;
}

#custom-gladly-chat-button #with-unread-message {
    display: none;
}

/*
  If "gladly-show" is there we show the button
*/
#custom-gladly-chat-button.gladly-show {
    display: block;
}

/*
  If user has gone through onboarding the "gladly-has-authenticated" class will be added,
  we hide the element with the text "START NEW CHAT" and show the element with the text
  "CONTINUE EXISTING CHAT"
*/
#custom-gladly-chat-button.gladly-has-authenticated #with-no-active-chat {
    display: none;
}

#custom-gladly-chat-button.gladly-has-authenticated #with-active-chat {
    display: block;
}

#custom-gladly-chat-button.gladly-unread #with-unread-message {
    display: block;
}
