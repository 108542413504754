@font-face {
                  font-family: 'ht-icons';
                  src: url('/_nextassets_/fonts/ht-icomoon-icons.woff2') format('woff2');
                  font-weight: normal;
                  font-style: normal;
                  font-display: swap;
                }

[class^="ht-"], [class*=" ht-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'ht-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ht-icon-settings:before {
  content: "\e98e";
}
.ht-partner-invoice:before {
  content: "\e98d";
  color: #5b5b5b;
}
.ht-partner-search:before {
  content: "\e98c";
}
.ht-partner-contact:before {
  content: "\e98b";
  color: #5b5b5b;
}
.ht-partner-faq:before {
  content: "\e98a";
}
.ht-partner-home:before {
  content: "\e989";
  color: #5b5b5b;
}
.ht-partner-myaccount:before {
  content: "\e988";
  color: #5b5b5b;
}
.ht-partner-orders:before {
  content: "\e987";
  color: #5b5b5b;
}
.ht-add-image:before {
  content: "\e9b8";
}
.ht-in-home-quote:before {
  content: "\e9b6";
}
.ht-install:before {
  content: "\e9b7";
}
.ht-circle-filled:before {
  content: "\e99d";
}
.ht-circle:before {
  content: "\e99e";
}
.ht-circle-slash:before {
  content: "\e99f";
}
.ht-undo:before {
  content: "\e9a0";
}
.ht-link:before {
  content: "\e9a1";
}
.ht-archive:before {
  content: "\e9ac";
}
.ht-at-sign:before {
  content: "\e9ad";
}
.ht-dropdown:before {
  content: "\e9ae";
}
.ht-duplicate:before {
  content: "\e9af";
}
.ht-equals:before {
  content: "\e9b0";
}
.ht-multiselect:before {
  content: "\e9b1";
}
.ht-play2:before {
  content: "\e9b2";
}
.ht-textarea:before {
  content: "\e9b3";
}
.ht-type:before {
  content: "\e9b4";
}
.ht-unlink:before {
  content: "\e9b5";
}
.ht-home:before {
  content: "\e9ab";
}
.ht-wifi-setup:before {
  content: "\e9a8";
}
.ht-thermostat-setup:before {
  content: "\e9a9";
}
.ht-lock-setup:before {
  content: "\e9aa";
}
.ht-gift:before {
  content: "\e9a7";
}
.ht-earth-icon:before {
  content: "\e9a6";
}
.ht-cat-icon-home-security:before {
  content: "\e9a5";
}
.ht-logo-horizontal:before {
  content: "\e903";
}
.ht-logo-house-v2:before {
  content: "\e982";
}
.ht-logo-hellotech-home:before {
  content: "\e965";
}
.ht-logo-big:before {
  content: "\e956";
}
.ht-mask:before {
  content: "\e92a";
  color: #0992e9;
}
.ht-disinfect:before {
  content: "\e92f";
  color: #0992e9;
}
.ht-icon-home-office:before {
  content: "\e990";
}
.ht-icon-smart-home:before {
  content: "\e991";
}
.ht-icon-tv-mounting:before {
  content: "\e98f";
}
.ht-cat-icon-virtual:before {
  content: "\ea66";
}
.ht-home-office-outline:before {
  content: "\ea5e";
}
.ht-mobile-tablet-outline:before {
  content: "\ea5f";
}
.ht-smart-home-outline:before {
  content: "\ea60";
}
.ht-tv-mounting-outline:before {
  content: "\ea61";
}
.ht-wifi-network-outline:before {
  content: "\ea62";
}
.ht-computer-support-outline:before {
  content: "\ea63";
}
.ht-audio-video-outline:before {
  content: "\ea65";
}
.ht-v2-gift .path1:before {
  content: "\ea4c";
  color: rgb(232, 129, 93);
}
.ht-v2-gift .path2:before {
  content: "\ea4d";
  margin-left: -0.9501953125em;
  color: rgb(246, 155, 118);
}
.ht-v2-gift .path3:before {
  content: "\ea4e";
  margin-left: -0.9501953125em;
  color: rgb(216, 57, 69);
}
.ht-v2-gift .path4:before {
  content: "\ea4f";
  margin-left: -0.9501953125em;
  color: rgb(216, 57, 69);
}
.ht-v2-gift .path5:before {
  content: "\ea50";
  margin-left: -0.9501953125em;
  color: rgb(234, 61, 83);
}
.ht-v2-gift .path6:before {
  content: "\ea51";
  margin-left: -0.9501953125em;
  color: rgb(216, 57, 69);
}
.ht-v2-gift .path7:before {
  content: "\ea52";
  margin-left: -0.9501953125em;
  color: rgb(216, 57, 69);
}
.ht-flying-clock:before {
  content: "\ea4a";
}
.ht-bbb-logo:before {
  content: "\e942";
}
.ht-logo-stacked:before {
  content: "\e94a";
}
.ht-logo-HellotechNow:before {
  content: "\e914";
}
.ht-logo:before {
  content: "\e944";
}
.ht-icon-smart-home-wifi:before {
  content: "\e95b";
}
.ht-icon-discount:before {
  content: "\e95c";
}
.ht-logo-house-plus:before {
  content: "\ea53";
}
.ht-logo-house-black:before {
  content: "\e95d";
}
.ht-icon-in-home-multi-visits:before {
  content: "\e95e";
}
.ht-icon-premium:before {
  content: "\e95f";
}
.ht-plans:before {
  content: "\e955";
}
.ht-play-rounded:before {
  content: "\e952";
}
.ht-info:before {
  content: "\e943";
  color: #faca69;
}
.ht-logo-house:before {
  content: "\e93b";
  color: #58c1e8;
}
.ht-check-mark-2:before {
  content: "\e968";
}
.ht-icon-devices:before {
  content: "\e907";
}
.ht-increase-sales:before {
  content: "\e939";
}
.ht-icon-trusted:before {
  content: "\e908";
}
.ht-icon-convenient:before {
  content: "\e909";
}
.ht-icon-guranteed:before {
  content: "\e90a";
}
.ht-phone247:before {
  content: "\e915";
}
.ht-phone:before {
  content: "\e930";
}
.ht-arrow-down:before {
  content: "\e90f";
  color: #00c9bc;
}
.ht-arrow-right:before {
  content: "\e953";
  color: #00c9bc;
}
.ht-arrow-up:before {
  content: "\e90e";
  color: #00c9bc;
}
.ht-arrow-left:before {
  content: "\e954";
  color: #00c9bc;
}
.ht-arrowUp:before {
  content: "\e604";
}
.ht-arrowRight:before {
  content: "\e603";
}
.ht-arrowDown:before {
  content: "\e601";
}
.ht-arrowLeft:before {
  content: "\e602";
}
.ht-delete:before {
  content: "\e928";
  color: #777;
}
.ht-star:before {
  content: "\e92b";
  color: #ddd;
}
.ht-swipe:before {
  content: "\e92e";
  color: #ddd;
}
.ht-statusicon_alert .path1:before {
  content: "\e916";
  color: rgb(237, 28, 36);
}
.ht-statusicon_alert .path2:before {
  content: "\e917";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ht-statusicon_alert .path3:before {
  content: "\e918";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ht-statusicon_followup:before {
  content: "\e919";
  color: #58595b;
}
.ht-statusicon_remote:before {
  content: "\e91a";
}
.ht-statusicon_unserviceable:before {
  content: "\e91b";
  color: #f27174;
}
.ht-statusicon_cancelled:before {
  content: "\e91e";
  color: #f27174;
}
.ht-statusicon_complete:before {
  content: "\e91f";
  color: #3db54a;
}
.ht-statusicon_paused:before {
  content: "\e920";
  color: #3cb3d8;
}
.ht-statusicon_inprogress:before {
  content: "\e921";
  color: #3db2d7;
}
.ht-statusicon_assigned:before {
  content: "\e922";
  color: #febe3a;
}
.ht-statusicon_searching:before {
  content: "\e923";
  color: #febe3a;
}
.ht-statusicon_pending:before {
  content: "\e931";
  color: #febe3a;
}
.ht-statusicon_unredeemed:before {
  content: "\e932";
  color: #808285;
}
.ht-statusicon_abandoned:before {
  content: "\e936";
  color: #808285;
}
.ht-statusicon_plan-plain:before {
  content: "\e924";
  color: #808080;
}
.ht-statusicon_incomplete:before {
  content: "\e941";
  color: #808285;
}
.ht-statusicon_inactive:before {
  content: "\e949";
  color: #808285;
}
.ht-chat:before {
  content: "\e92c";
}
.ht-smb:before {
  content: "\e96a";
}
.ht-pdf:before {
  content: "\e96b";
}
.ht-ellipsis:before {
  content: "\e96d";
}
.ht-v2-neutral-yellow-circle:before {
  content: "\e96f";
  color: #faca71;
}
.ht-v2-red-circle-x:before {
  content: "\e970";
  color: #e64f49;
}
.ht-v2-green-checkmark:before {
  content: "\e971";
  color: #1dd09c;
}
.ht-v2-skinny-arrow-right:before {
  content: "\ea48";
}
.ht-social-instagram .path1:before {
  content: "\e960";
  color: rgb(67, 70, 72);
}
.ht-social-instagram .path2:before {
  content: "\e961";
  margin-left: -1em;
  color: rgb(0, 201, 188);
}
.ht-social-instagram .path3:before {
  content: "\e962";
  margin-left: -1em;
  color: rgb(0, 201, 188);
}
.ht-social-instagram .path4:before {
  content: "\e963";
  margin-left: -1em;
  color: rgb(0, 201, 188);
}
.ht-social-yelp .path1:before {
  content: "\e905";
  color: rgb(67, 70, 72);
}
.ht-social-yelp .path2:before {
  content: "\e906";
  margin-left: -1em;
  color: rgb(0, 201, 188);
}
.ht-social-yelp-grey .path1:before {
  content: "\e904";
  color: rgb(179, 180, 181);
}
.ht-social-yelp-grey .path2:before {
  content: "\e9a4";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ht-social-fb .path1:before {
  content: "\e926";
  color: rgb(67, 70, 72);
}
.ht-social-fb .path2:before {
  content: "\e927";
  margin-left: -1em;
  color: rgb(67, 190, 172);
}
.ht-social-fb-grey .path1:before {
  content: "\e910";
  color: rgb(179, 180, 181);
}
.ht-social-fb-grey .path2:before {
  content: "\e911";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ht-social-twitter .path1:before {
  content: "\e925";
  color: rgb(67, 70, 72);
}
.ht-social-twitter .path2:before {
  content: "\e938";
  margin-left: -1em;
  color: rgb(67, 190, 172);
}
.ht-social-twitter-grey .path1:before {
  content: "\e912";
  color: rgb(179, 180, 181);
}
.ht-social-twitter-grey .path2:before {
  content: "\e913";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ht-social-google-plus .path1:before {
  content: "\e93a";
  color: rgb(67, 70, 72);
}
.ht-social-google-plus .path2:before {
  content: "\e959";
  margin-left: -1em;
  color: rgb(67, 190, 172);
}
.ht-social-google-plus-grey .path1:before {
  content: "\e91c";
  color: rgb(179, 180, 181);
}
.ht-social-google-plus-grey .path2:before {
  content: "\e91d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ht-hamburger:before {
  content: "\e94b";
}
.ht-double-arrow-down:before {
  content: "\e985";
  color: #00c9bc;
}
.ht-phone1:before {
  content: "\e986";
  color: #00c9bc;
}
.ht-double-right-arrow:before {
  content: "\e66d";
  color: #000;
}
.ht-double-left-arrow:before {
  content: "\e994";
  color: #000;
}
.ht-question1:before {
  content: "\e995";
  color: #00c9bc;
}
.ht-question2:before {
  content: "\e997";
}
.ht-play1:before {
  content: "\e998";
}
.ht-arrow-up-circle:before {
  content: "\e999";
}
.ht-arrow-right-circle:before {
  content: "\e99a";
}
.ht-arrow-down-circle:before {
  content: "\e99b";
}
.ht-arrow-left-circle:before {
  content: "\e99c";
}
.ht-round-x-fill:before {
  content: "\e9a3";
}
.ht-star-outline:before {
  content: "\e981";
}
.ht-box:before {
  content: "\e967";
}
.ht-credit-card:before {
  content: "\e96c";
}
.ht-money:before {
  content: "\e966";
}
.ht-minus-icon:before {
  content: "\e964";
}
.ht-circle-add:before {
  content: "\e95a";
}
.ht-clock:before {
  content: "\e948";
}
.ht-social-distance:before {
  content: "\e900";
  color: #0992e9;
}
.ht-icon-send:before {
  content: "\e993";
}
.ht-icon-lightning:before {
  content: "\e992";
}
.ht-round-check-fill:before {
  content: "\e946";
}
.ht-alert-triangle-fill .path1:before {
  content: "\e947";
  color: rgb(38, 55, 70);
}
.ht-alert-triangle-fill .path2:before {
  content: "\e983";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ht-alert-triangle-fill .path3:before {
  content: "\e984";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.ht-search:before {
  content: "\e93c";
}
.ht-question:before {
  content: "\e945";
}
.ht-list:before {
  content: "\e92d";
}
.ht-play:before {
  content: "\e901";
}
.ht-tool:before {
  content: "\e9a2";
}
.ht-truck:before {
  content: "\e94c";
}
.ht-edit-plain:before {
  content: "\e94d";
}
.ht-map-pin:before {
  content: "\e94e";
}
.ht-user:before {
  content: "\e94f";
}
.ht-checkmark-plain:before {
  content: "\e950";
}
.ht-checkmark-square:before {
  content: "\e951";
}
.ht-calendar-plain:before {
  content: "\e958";
}
.ht-info-filled:before {
  content: "\e97f";
}
.ht-info-outline:before {
  content: "\e980";
}
.ht-rating-star:before {
  content: "\ea5d";
}
.ht-helper:before {
  content: "\ea5c";
}
.ht-hamburger-v2:before {
  content: "\ea5b";
}
.ht-cart-outlined:before {
  content: "\ea56";
}
.ht-phone-outlined:before {
  content: "\ea57";
}
.ht-profile-outlined:before {
  content: "\ea58";
}
.ht-calendar-v2:before {
  content: "\ea54";
}
.ht-v2-star:before {
  content: "\ea4b";
  color: #f0b64a;
}
.ht-toolkit:before {
  content: "\ea49";
}
.ht-cart2:before {
  content: "\ea47";
}
.ht-checkmark-broken-circle:before {
  content: "\ea46";
}
.ht-clipboard:before {
  content: "\ea45";
}
.ht-v2-close-icon:before {
  content: "\e977";
}
.ht-v2-plus-icon:before {
  content: "\e976";
}
.ht-v2-edit-icon:before {
  content: "\e975";
}
.ht-x:before {
  content: "\e93d";
}
.ht-check-mark:before {
  content: "\e93e";
}
.ht-check-mark-box:before {
  content: "\e957";
}
.ht-cart:before {
  content: "\e929";
}
.ht-close:before {
  content: "\e90c";
}
.ht-calendar:before {
  content: "\e90b";
}
.ht-message:before {
  content: "\e969";
}
.ht-signin:before {
  content: "\e90d";
}
.ht-profile:before {
  content: "\e935";
}
.ht-smart-home:before {
  content: "\e902";
}
.ht-speakers:before {
  content: "\e93f";
}
.ht-tv-mounting:before {
  content: "\e940";
}
.ht-mobile-phone:before {
  content: "\e933";
}
.ht-wifi:before {
  content: "\e934";
}
.ht-computer:before {
  content: "\e937";
}
.ht-chat-v2:before {
  content: "\e96e";
}
.ht-v2-info-icon-default:before {
  content: "\e972";
}
.ht-v2-info-icon-hover:before {
  content: "\e973";
  color: #00c9bc;
}
.ht-v2-smart-home:before {
  content: "\e97d";
}
.ht-v2-computer:before {
  content: "\e97c";
}
.ht-v2-wifi:before {
  content: "\e97b";
}
.ht-v2-mobile:before {
  content: "\e97a";
}
.ht-v2-speaker:before {
  content: "\e979";
}
.ht-v2-television:before {
  content: "\e978";
}
.ht-chevron:before {
  content: "\e974";
}
.ht-chevron-left:before {
  content: "\ea59";
}
.ht-chevron-up:before {
  content: "\ea5a";
}
.ht-chevron-right:before {
  content: "\ea55";
}
.ht-checkmark-v2:before {
  content: "\ea64";
}
.ht-search1:before {
  content: "\e996";
}
.ht-v2-skinny-arrow:before {
  content: "\e97e";
}
